<template>
  <div>
    <div class="order-detail" v-if="currentOrder !== null && !myIsLoading">
      <order-qrcode :id="currentOrder.id" />
      <div class="order-info text-muted p-4">
        <ul class="list-unstyled mb-0">
          <li
            style="
            font-size: large;
            color: var(--main-color);
            margin-bottom: 10px;
          "
          >
            {{ $t("ORDER.STATUS.TITLE") }}：
            <template v-if="currentOrder.status === 0">
              <!-- {{
              $t("ORDER.STATUS.NEW")
              }} -->
              訂單已送出
            </template>
            <template v-else-if="currentOrder.status === 1">
              {{
              $t("ORDER.STATUS.PROCESSING")
              }}
            </template>
            <template
              v-else-if="currentOrder.status === 3 || currentOrder.status === 6"
            >{{ $t("ORDER.STATUS.CANCEL") }}</template>
            <template v-else-if="currentOrder.status === 2">
              {{
              $t("ORDER.STATUS.FINISH")
              }}
            </template>
            <template v-else-if="currentOrder.status === 4">
              {{
              $t("ORDER.STATUS.ABNORMAL")
              }}
            </template>
            <template v-else-if="currentOrder.status === 5">
              {{
              $t("ORDER.STATUS.REFUND")
              }}
            </template>
          </li>
          <li>訂單編號：{{ currentOrder.order_number }}</li>
          <li>下單時間：{{ currentOrder.created_at }}</li>

          <template
            v-if="currentOrder.type === 0 || currentOrder.type === 6|| currentOrder.type === 1|| currentOrder.type === 2"
          >
            <li>
              {{ $t("ORDER.MEAL_WAY.TITLE.FOR_HERE") }}：
              <span v-if="currentOrder.type === 0">預約內用</span>
              <span v-if="currentOrder.type === 1">到店自取</span>
              <span v-if="currentOrder.type === 2">店家外送</span>
              <span v-if="currentOrder.type === 6">現場點餐</span>
            </li>
            <li v-if="currentOrder.order_diner.adult > 0 ">
              {{
              $t("ORDER.PEOPLE", {
              adult: currentOrder.order_diner.adult,
              child: currentOrder.order_diner.child,
              })
              }}
            </li>
            <li v-if="currentOrder.order_diner">
              {{ $t("ORDER.MEAL_AT.FOR_HERE") }}：{{
              currentOrder.order_diner.meal_at
              }}
            </li>
            <template v-if="buyerOfOrder">
              <li>姓名：{{ buyerOfOrder.name }}</li>
              <li>電話：{{ buyerOfOrder.phone }}</li>
            </template>
            <template v-if="receiverOfOrder">
              <li>{{ $t("ORDER.RECEIVER.NAME") }}：{{ receiverOfOrder.name }}</li>
              <li>{{ $t("ORDER.RECEIVER.PHONE") }}：{{ receiverOfOrder.phone }}</li>
              <li>
                {{ $t("ORDER.RECEIVER.ADDRESS") }}：{{ receiverOfOrder.city
                }}{{ receiverOfOrder.cityarea }}{{ receiverOfOrder.address }}
              </li>
            </template>
          </template>
          <!-- <template v-else>
            <li>
              {{ $t("ORDER.MEAL_WAY.TITLE.TO_GO") }}：
              <template
                v-if="currentOrder.type == 1"
              >{{ $t("ORDER.MEAL_WAY.TO_GO") }}</template>
              <template v-else-if="currentOrder.type == 2">{{ $t("ORDER.MEAL_WAY.ORDER_IN") }}</template>
            </li>
            <li>
              {{ $t("ORDER.MEAL_AT.TO_GO") }}：{{
              currentOrder.order_diner.meal_at
              }}
            </li>
            <template v-if="buyerOfOrder">
              <li>姓名：{{ buyerOfOrder.name }}</li>
              <li>電話：{{ buyerOfOrder.phone }}</li>
            </template>
            <template v-if="receiverOfOrder">
              <li>{{ $t("ORDER.RECEIVER.NAME") }}：{{ receiverOfOrder.name }}</li>
              <li>{{ $t("ORDER.RECEIVER.PHONE") }}：{{ receiverOfOrder.phone }}</li>
              <li>
                {{ $t("ORDER.RECEIVER.ADDRESS") }}：{{ receiverOfOrder.city
                }}{{ receiverOfOrder.cityarea }}{{ receiverOfOrder.address }}
              </li>
            </template>
          </template>-->
          <li>{{ $t("ORDER.PAYMENT_WAY.TITLE") }}：{{ currentOrder.order_payment.payment_name }}</li>
          <li>
            付款狀態：
            <span v-if="currentOrder.payment_status == 0" style="color: red">未付款</span>
            <span v-if="currentOrder.payment_status == 1">未結清</span>
            <span v-if="currentOrder.payment_status == 2">已付款</span>
            <span v-if="currentOrder.payment_status == 3">付款失敗</span>
            <span v-if="currentOrder.payment_status == 4">超過付款時間</span>
          </li>
          <template v-if="currentOrder.order_invoice">
            <li>
              發票類型：
              <template v-if="currentOrder.order_invoice.carrier_type == 3">載具</template>
              <template v-else>
                <span v-if="currentOrder.order_invoice.vat_number != ''">電子發票 - 公司</span>
                <span v-else-if="currentOrder.order_invoice.npo_ban != ''">捐贈發票</span>
                <span v-else-if="currentOrder.order_invoice.carrier_type == ''">電子發票 - 個人</span>
              </template>
            </li>
            <li>
              {{ $t("ORDER.INVOICE.NUMBER") }}：{{
              currentOrder.order_invoice.number
              }}
              <template
                v-if="currentOrder.order_invoice.status === 1"
              >
                <span style="color: red">(作廢)</span>
              </template>
            </li>
            <li v-if="currentOrder.order_invoice.vat_number">
              {{ $t("ORDER.INVOICE.VAT_NUMBER") }}：{{
              currentOrder.order_invoice.vat_number
              }}
            </li>
            <li
              v-if="
              currentOrder.order_invoice.carrier_type === 3 &&
              currentOrder.order_invoice.carrier_id
            "
            >
              {{ $t("ORDER.INVOICE.CARRIER_ID") }}：{{
              currentOrder.order_invoice.carrier_id
              }}
            </li>
            <li v-if="currentOrder.order_invoice.npo_ban">
              {{ $t("ORDER.INVOICE.NPO_BAN") }}：{{
              currentOrder.order_invoice.npo_ban
              }}
            </li>
            <li
              v-if="currentOrder.order_invoice.invoice_paper"
            >{{ $t("ORDER.INVOICE.INVOICE_PAPER") }}</li>
          </template>
          <template v-else>
            <li v-if="[1, 2, 3, 4].includes(currentOrder.invoice_type)">
              發票類型：
              <span v-if="currentOrder.invoice_type == 1">電子發票 - 個人</span>
              <span v-else-if="currentOrder.invoice_type == 2">電子發票 - 公司</span>
              <span v-else-if="currentOrder.invoice_type == 3">載具</span>
              <span v-else-if="currentOrder.invoice_type == 4">捐贈發票</span>
            </li>
            <li v-if="currentOrder.invoice_type == 2">
              統一編號：
              <span v-if="currentOrder.invoice_info">
                {{
                currentOrder.invoice_info.vat_number
                }}
              </span>
            </li>
            <li v-if="currentOrder.invoice_type == 3">
              手機條碼載具：
              <span v-if="currentOrder.invoice_info">
                {{
                currentOrder.invoice_info.carrier_id
                }}
              </span>
            </li>
            <li v-if="currentOrder.invoice_type == 4">
              愛心碼：
              <span v-if="currentOrder.invoice_info">
                {{
                currentOrder.invoice_info.carrier_id
                }}
              </span>
            </li>
          </template>
          <li
            v-if="currentOrder.order_diner"
          >{{ $t("ORDER.COMMENT") }}：{{ currentOrder.order_diner.memo }}</li>
        </ul>
      </div>
      <div class="menu-list">
        <h3 class="detail-title text-center">商品明細</h3>
        <div class="item" v-for="item in currentOrder.order_items" :key="item.id">
          <div class="row align-items-center">
            <app-image :image_url="item.product.image_url"></app-image>
            <div class="col">
              <div class="title row no-gutters justify-content-between align-items-center">
                <h3>{{ item.product_name }}</h3>
              </div>
              <div class="introduction">{{ item.product_spec_1 }}</div>
              <!-- <div class="price">
              {{ item.final_price }}
              </div>-->
              <product-price :item="{ price: item.final_price, cartVip: item.is_vip }" />
            </div>
            <div class="col-auto">&times;{{ item.quantity }}</div>
          </div>
        </div>
        <!-- 小計欄位 -->
        <div
          class="cart-summary row no-gutters text-right"
          style="font-size:1rem; font-weight: 500"
        >
          <div class="col-12 px-2">
            <span>小計：</span>
            <span class="text-primary">{{ productQuantityOfOrder }}</span>
            <span>{{ $t("ORDER.TOTAL.GOODS_UNIT") }}</span>
            <span class="ml-3">{{ $t("ORDER.TOTAL.MONEY") }}：</span>
            <span class="text-main">${{ formatMoney(productPriceTotal) }}</span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>
          <!-- <div
          class="col-12 p-2"
          v-for="discount_item in currentOrder.order_discount"
          :key="discount_item.id"
        >
          <span>{{ discount_item.discount_name }}：</span>
          <span class="text-main">{{
            formatMoney(discount_item.discount_price)
          }}</span>
          <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>-->
          <div
            class="col-12 px-2"
            v-for="price_item in otherPriceOfOrder"
            :key="price_item.product_id"
          >
            <span>{{ price_item.product_name }}：</span>
            <span class="text-main">
              ${{
              formatMoney(price_item.final_price)
              }}
            </span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>
        </div>
        <!-- 總計欄位 -->
        <order-total :currentOrder="currentOrder" />
      </div>
    </div>

    <!--選單 SR-->
    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3" v-if="currentOrder !== null && currentOrder.status === 0">
          <button
            class="btn bg-danger btn-block shadow text-white my-2"
            data-toggle="modal"
            data-target="#popupConfirm"
            @click="onCancelButton"
          >{{ $t("ORDER.BUTTON.CANCEL") }}</button>
        </div>
        <div class="col px-3">
          <a
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
            @click="onBackButton"
          >{{ $t("ORDER.BUTTON.BACK") }}</a>
        </div>
      </div>
    </nav>
    <!--選單 END-->

    <el-dialog :title="$t('FORM.CANCEL_CONFIRM.TITLE')" v-model="state.dialogFormVisible">
      <div class="modal-body">
        <p>{{ $t("FORM.CANCEL_CONFIRM.DESCRIPTION") }}</p>
        <p class="text-danger">{{ $t("FORM.CANCEL_CONFIRM.DESCRIPTION_PS") }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer modal-footer">
          <el-button
            type="primary"
            class="btn btn-main"
            @click="onCancelButton(state.cancelId)"
          >{{ $t("FORM.CANCEL_CONFIRM.CONFIRM") }}</el-button>
          <el-button
            class="btn btn-secondary"
            @click="state.dialogFormVisible = false"
          >{{ $t("FORM.CANCEL_CONFIRM.CANCEL") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { computed, getCurrentInstance, reactive, inject, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { formatMoney } from "@/utils/tools";
import AppImage from "@/components/AppImage.vue";
import ProductPrice from "@/components/ProductPrice.vue";
import OrderQrcode from "@/components/OrderQrcode.vue";
import OrderTotal from "@/components/OrderTotal.vue";

export default {
  name: "Order",
  components: {
    AppImage,
    ProductPrice,
    OrderQrcode,
    OrderTotal,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const order_id = route.params.id;
    const state = reactive({
      dialogFormVisible: false,
      cancelId: order_id,
      shop_name: inject("shop_name"),
    });

    //getter
    const isLoading = computed(() => store.getters["orders/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const isCanceling = computed(() => store.getters["orders/isCanceling"]);
    const canceledData = computed(() => store.getters["orders/canceledData"]);

    let productPriceTotal = ref(0);
    //取資料
    const fetchOrderData = (order_id) => {
      store
        .dispatch("orders/fetchDetailOrder", order_id)
        .then(() => {
          console.log(currentOrder.value)
          currentOrder.value.order_items.forEach((item) => {
            productPriceTotal.value = productPriceTotal.value + item.final_price * item.quantity
          })
          // otherPriceOfOrder.value.forEach((item) => {
          //   otherPriceTotal = otherPriceTotal + item.final_price;
          // });
          // console.log(otherPriceTotal)
          // productPriceTotal.value =
          //   currentOrder.value.subtotal - otherPriceTotal;
        })
        .catch(() => {
          router.push("/orders");
        }); //取資料;
    };
    fetchOrderData(order_id);
    const currentOrder = computed(() => store.getters["orders/order"]);
    // console.log(currentOrder);
    if (currentOrder.value === undefined) onBackButton();
    //額外費用
    const otherPriceOfOrder = computed(
      () => store.getters["orders/otherPriceOfOrder"]
    );
    console.log(otherPriceOfOrder);

    //數量
    const productQuantityOfOrder = computed(
      () => store.getters["orders/productQuantityOfOrder"]
    );
    // console.log(productQuantityOfOrder)
    //訂購人
    const buyerOfOrder = computed(() => store.getters["orders/buyerOfOrder"]);
    //收件人
    const receiverOfOrder = computed(
      () => store.getters["orders/receiverOfOrder"]
    );
    // console.log(buyerOfOrder,receiverOfOrder)
    //method
    const onCancelButton = () => {
      state.dialogFormVisible = false;
      store
        .dispatch("orders/cancelOrder", state.cancelId)
        .then(() => {
          canceledData.value.is_cancel === true
            ? onCancelAlert("success")
            : onCancelAlert("error");
        })
        .catch(() => {
          onCancelAlert("error");
        });
    };
    const onBackButton = () => {
      router.push("/orders");
    };
    const onCancelAlert = (type) => {
      let message =
        type === "success" ? t("ORDER.CANCEL.SUCCESS") : t("ORDER.CANCEL.FAIL");
      proxy.$message({
        type: type,
        message: message,
      });
      router.push("/orders");
    };

    return {
      state,
      myIsLoading,
      currentOrder,
      otherPriceOfOrder,
      productQuantityOfOrder,
      buyerOfOrder,
      receiverOfOrder,
      onCancelButton,
      onBackButton,
      formatMoney,
      productPriceTotal,
    };
  },
};
</script>
